import { TelegramRecipientModel } from "@Pages/telegram/data/recipient.model";
import { PATH_ROLES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const UpdateRoleUsecase: (
  id: string,
  title?: string,
  role?: string,
  access?: string[],
  telegram_groups?: TelegramRecipientModel[]
) => Promise<boolean> = async (id, title, role, access, telegram_groups) => {
  try {
    let formData = new FormData();
    if (title) formData.append("title", title);
    if (role) formData.append("role", role);
    if (access) formData.append("access", `${access.join(";")};`);
    if (telegram_groups) formData.append("telegram_group", JSON.stringify(telegram_groups));

    await client.put(PATH_ROLES + "/" + id, formData);
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
