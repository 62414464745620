import { TelegramRecipientModel } from "@Pages/telegram/data/recipient.model";
import { PATH_ROLES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const AddRoleUsecase: (
  title: string,
  role: string,
  access: string[],
  groups: TelegramRecipientModel[],
) => Promise<string> = async (title, role, access, groups) => {
  try {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("role", role);
    formData.append("telegram_group", JSON.stringify(groups));
    formData.append("access", `${access.join(";")};`);

    let response = await client.post(PATH_ROLES, formData);
    return response.data.message;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
