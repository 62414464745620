import { ContentHeader } from "@Components/ContentHeader.component";
import { Tabs } from "@Components/tabs/Tabs.component";
import { FC, useEffect, useState } from "react";
import { SendMessageSection } from "./sections/SendMessage.section";
import { RecipientListSection } from "./sections/RecipientList.section";
import { AutoMessageSection } from "./sections/AutoMessage.section";
import { TelegramRecipientModel } from "../data/recipient.model";
import { LoadSettingUsecase } from "@Pages/setting/domain/LoadSetting.usecase";
import { TELEGRAM_RECIPIENT_LIST } from "@Pages/setting/utils/const";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";

export type RecipientListProps = {
    recipientList: TelegramRecipientModel[];
}

export const TelegramMainPage: FC = () => {
    const toasterContext = UseToasterContext();

    const [recipientList, setRecipientList] = useState<TelegramRecipientModel[]>([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    useEffect(() => {
        loadRecipientList();
    }, []);

    const loadRecipientList = async () => {
        try {
            let response = await LoadSettingUsecase(TELEGRAM_RECIPIENT_LIST);
            if (response[TELEGRAM_RECIPIENT_LIST]) {
                setRecipientList(JSON.parse(response[TELEGRAM_RECIPIENT_LIST]));
            }
        } catch (e) {
            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel(e.message, "", ToasterType.DANGER),
            ]);
        }
    }

    const buildViewBasedOnTab = () => {
        switch (selectedTabIndex) {
            case 0:
                return <SendMessageSection recipientList={recipientList} />
            case 1:
                return <AutoMessageSection recipientList={recipientList} />
            case 2:
                return <RecipientListSection />
            default:
                return <div>Tab Not Found</div>

        }
    }

    return (
        <div className="m-4">
            <ContentHeader title="Telegram" />
            <div className="h-6"></div>

            <div>
                <Tabs
                    tabItems={["Send Message", "Auto Message", "Recipient List"]}
                    selectedIndex={selectedTabIndex}
                    onClickTab={setSelectedTabIndex}
                />
                {buildViewBasedOnTab()}
            </div>
        </div>
    )
}