/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import BasePage from "@Pages/common/presentation/Base.page";
import { LoginPage } from "@Pages/login/Login.page";
import { PageEditorProvider } from "@Pages/page/context/page-editor.context";
import {
  AddPagePage,
  AddPageType,
} from "@Pages/page/presentation/AddPage.page";
import UserListPage from "@Pages/user/presentation/Users.page";
import { DetailOrderPage } from "@Pages/order/presentation/DetailOrder.page";
import { AddProductPage } from "@Pages/product/presentation/AddProduct.page";
import { AddVoucherPage } from "@Pages/voucher/presentation/AddVoucher.page";
import { ModalProvider } from "@Pages/common/contexts/modal.context";
import { OrderListPage } from "@Pages/order/presentation/Order.page";
import { ProductListPage } from "@Pages/product/presentation/Product.page";
import { ToasterProvider } from "@Pages/common/contexts/toaster.context";
import { ResourcePage } from "@Pages/resources/presentation/Resources.page";
import { AddResourcePage } from "@Pages/resources/presentation/AddResource.page";
import { DetailResourcePage } from "@Pages/resources/presentation/DetailResource.page";
import { SettingsPage } from "@Pages/setting/presentation/Setting.page";
import { VoucherListPage } from "@Pages/voucher/presentation/Voucher.page";
import { DetailVoucherPage } from "@Pages/voucher/presentation/DetailVoucher.page";
import { PreviewPagePage } from "@Pages/page/presentation/PreviewPage.page";
import { PageListPage } from "@Pages/page/presentation/Page.page";
import { SettingsStaticPagesPage } from "@Pages/setting/presentation/SettingStaticPages.page";
import { RecordingPage } from "@Pages/records/presentation/Recordings.page";
import { AddRecordPage } from "@Pages/records/presentation/AddRecord.page";
import { DetailRecordPage } from "@Pages/records/presentation/DetailRecord.page";
import { useEffect } from "react";
import { UserSession } from "@Utils/UserSession";
import { client } from "@Utils/ApiService";
import { PATH_PROFILE } from "@Utils/ApiConst";
import { CoursesPage } from "@Pages/courses/presentation/Courses.page";
import { AddCoursePage } from "@Pages/courses/presentation/AddCourse.page";
import { DetailCoursePage } from "@Pages/courses/presentation/DetailCourse.page";
import RolesListPage from "@Pages/role/presentation/Roles.page";
import { AddRolePage } from "@Pages/role/presentation/AddRole.page";
import { DetailRolePage } from "@Pages/role/presentation/DetailRole.page";
import { NotFoundPage } from "@Pages/common/presentation/404.page";
import { StockAnalysisListPage } from "@Pages/page/presentation/StockAnalysis.page";
import { GetResponsePage } from "@Pages/get-response/presentation/GetResponse.page";
import { DetailUserPage } from "@Pages/user/presentation/DetailUser.page";
import { LandingPageListPage } from "@Pages/landing-page/presentation/LandingPage.page";
import { AddLandingPagePage } from "@Pages/landing-page/presentation/AddLandingPage.page";
import { AffiliatePage } from "@Pages/affiliate/presentation/Affiliate.page";
import { AddBulkResourcePage } from "@Pages/resources/presentation/AddBulkResource.page";
import { ExpiredMembersPage } from "@Pages/expiredMembers/presentation/ExpiredMembers.page";
import { TelegramMainPage } from "@Pages/telegram/presentation/TelegramMain.page";

function MidasApp() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let pathNameList = location.pathname.split("/");
    let pathName = "::";
    if (pathNameList.length > 2) {
      pathName += (pathNameList[0] + "::" + pathNameList[1]).toLowerCase();
    } else if (pathNameList.length > 0) {
      pathName += pathNameList.join("::").toLowerCase();
    }

    if (UserSession.checkIsLoggedIn()) {
      client
        .get(PATH_PROFILE)
        .then((response) => {
          let data = response.data.data;
          UserSession.login(
            data.id,
            data.fullname,
            data.email,
            UserSession.getToken(),
            data.role,
            data.picture_url,
            data.member_active_roles
          );

          if (!UserSession.canAccess(pathName)) {
            navigate("/404");
          }
        })
        .catch((error) => {
          if (error.response.data.message === "Not Authorized") {
            let baseUrl = window.location.origin;
            UserSession.logout();
            window.location.href = `${baseUrl}/login?continue=${window.location.href}`;
          }
        });
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <ModalProvider>
      <ToasterProvider>
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route
            path="pages/add"
            element={
              <PageEditorProvider>
                <AddPagePage type={AddPageType.ADD_PAGE} />
              </PageEditorProvider>
            }
          />
          <Route
            path="pages/detail/:id"
            element={
              <PageEditorProvider>
                <AddPagePage type={AddPageType.DETAIL_PAGE} />
              </PageEditorProvider>
            }
          />
          <Route
            path="analysis/add"
            element={
              <PageEditorProvider>
                <AddPagePage type={AddPageType.ADD_ANALYSIS} />
              </PageEditorProvider>
            }
          />
          <Route
            path="analysis/detail/:id"
            element={
              <PageEditorProvider>
                <AddPagePage type={AddPageType.DETAIL_ANALYSIS} />
              </PageEditorProvider>
            }
          />
          <Route path="pages/preview/:slug" element={<PreviewPagePage />} />
          <Route path="/" element={<BasePage />}>
            <Route index element={<OrderListPage />} />
            <Route path="analysis">
              <Route index element={<StockAnalysisListPage />} />
            </Route>
            <Route path="courses">
              <Route index element={<CoursesPage />} />
              <Route path="add" element={<AddCoursePage />} />
              <Route path="detail/:slug" element={<DetailCoursePage />} />
            </Route>
            <Route path="landing-page">
              <Route index element={<LandingPageListPage />} />
              <Route path="add" element={<AddLandingPagePage />} />
              <Route path="detail/:id" element={<AddLandingPagePage />} />
            </Route>
            <Route path="get-response">
              <Route index element={<GetResponsePage />} />
            </Route>
            <Route path="records">
              <Route index element={<RecordingPage />} />
              <Route path="add" element={<AddRecordPage />} />
              <Route path="detail/:id" element={<DetailRecordPage />} />
            </Route>
            <Route path="resources">
              <Route index element={<ResourcePage />} />
              <Route path="add" element={<AddResourcePage />} />
              <Route path="add-bulk" element={<AddBulkResourcePage />} />
              <Route path="detail/:id" element={<DetailResourcePage />} />
            </Route>
            <Route path="roles">
              <Route index element={<RolesListPage />} />
              <Route path="add" element={<AddRolePage />} />
              <Route path="detail/:id" element={<DetailRolePage />} />
            </Route>
            <Route path="users">
              <Route index element={<UserListPage />} />
              <Route path="detail/:id" element={<DetailUserPage />} />
            </Route>
            <Route path="expired-members">
              <Route index element={<ExpiredMembersPage />} />
            </Route>
            <Route path="orders">
              <Route index element={<OrderListPage />} />
              <Route path="detail/:id" element={<DetailOrderPage />} />
            </Route>
            <Route path="affiliate" element={<AffiliatePage />} />
            <Route path="pages">
              <Route index element={<PageListPage />} />
            </Route>
            <Route path="products">
              <Route index element={<ProductListPage />} />
              <Route
                path="add"
                element={<AddProductPage isNewProduct={true} />}
              />
              <Route
                path="detail/:id"
                element={<AddProductPage isNewProduct={false} />}
              />
            </Route>
            <Route path="settings" element={<SettingsPage />} />
            <Route
              path="settings/static-pages"
              element={<SettingsStaticPagesPage />}
            />
            <Route path="telegram" element={<TelegramMainPage />} />
            <Route path="vouchers">
              <Route index element={<VoucherListPage />} />
              <Route path="add" element={<AddVoucherPage />} />
              <Route path="detail/:id" element={<DetailVoucherPage />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ToasterProvider>
    </ModalProvider>
  );
}

export default MidasApp;
