import { PATH_TELEGRAM_GET_CHAT_DETAILS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { TelegramChatModel } from "../data/chat.model";

export const GetChatDetailUseCase: (chatId: string) => Promise<TelegramChatModel> = async (chatId) => {
    try {
        let response = await client.get(`${PATH_TELEGRAM_GET_CHAT_DETAILS}/${chatId}`);
        return JSON.parse(response.data.data) as TelegramChatModel
    } catch (e) {
        throw new Error(e.response.data.message);
    }
}