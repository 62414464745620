import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { LoadSettingUsecase } from "@Pages/setting/domain/LoadSetting.usecase";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import { TELEGRAM_RECIPIENT_LIST } from "@Pages/setting/utils/const";
import { TelegramChatModel } from "@Pages/telegram/data/chat.model";
import { TelegramRecipientModel } from "@Pages/telegram/data/recipient.model";
import { GetChatDetailUseCase } from "@Pages/telegram/domain/GetChatDetail.usecase";
import { FC, useEffect, useState } from "react"
import { FaTrashAlt } from "react-icons/fa";

export const RecipientListSection: FC = () => {
    const toasterContext = UseToasterContext();

    const [isLoading, setIsLoading] = useState(false);
    const [lookupChatId, setLookupChatId] = useState("");
    const [lookupChatDetail, setLookupChatDetail] = useState<TelegramChatModel | null>(null);
    const [recipientList, setRecipientList] = useState<TelegramRecipientModel[]>([]);

    useEffect(() => {
        loadRecipientList();
    }, []);

    const loadRecipientList = async () => {
        try {
            setIsLoading(true);
            let response = await LoadSettingUsecase(TELEGRAM_RECIPIENT_LIST);
            if (response[TELEGRAM_RECIPIENT_LIST]) {
                setRecipientList(JSON.parse(response[TELEGRAM_RECIPIENT_LIST]));
            }
        } catch (e) {
            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel(e.message, "", ToasterType.DANGER),
            ]);
        } finally {
            setIsLoading(false);
        }
    }

    const onGetDetails = async () => {
        try {
            setIsLoading(true);
            let result = await GetChatDetailUseCase(lookupChatId);
            setLookupChatDetail(result);
        } catch (e) {

        } finally {
            setIsLoading(false);
        }
    }

    const onAddRecipient = async () => {
        if (isLoading) return;

        let isExist = recipientList.some(recipient => recipient.id == lookupChatDetail?.id);
        if (isExist) {
            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel("Failed!", "Recipient already in the list.", ToasterType.DANGER),
            ]);
            return;
        }

        try {
            setIsLoading(true);
            let newList = [
                ...recipientList,
                { id: lookupChatDetail?.id, type: lookupChatDetail?.type, name: lookupChatDetail?.title ?? lookupChatDetail?.username }
            ]
            await SaveSettingsUsecase(true, TELEGRAM_RECIPIENT_LIST, JSON.stringify(newList));
        } catch (e) {
            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel("Failed!", e.message, ToasterType.DANGER),
            ]);
        } finally {
            loadRecipientList();
            clearAll();
        }
    }

    const deleteRecipient = async (index: number) => {
        try {
            setIsLoading(true);
            let newList = recipientList;
            newList.splice(index, 1);

            await SaveSettingsUsecase(true, TELEGRAM_RECIPIENT_LIST, JSON.stringify(newList));
        } catch (e) {
            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel("Failed!", e.message, ToasterType.DANGER),
            ]);
        } finally {
            loadRecipientList();
            clearAll();
        }
    }

    const clearAll = async () => {
        if (isLoading) return;

        setLookupChatDetail(null);
        setLookupChatId("");
    }

    return (
        <div>
            <div className="mt-4 font-bold text-lg">Recipient List</div>

            <div className="grid-1 gap-2 md:flex md:gap-4 mt-4">
                <input
                    className={`input-field-full-width`}
                    type="text"
                    value={lookupChatId}
                    placeholder="Input Chat ID to add."
                    onChange={e => setLookupChatId(e.target.value)}
                />

                <button
                    className="mt-3 w-full md:mt-0 md:w-40 button-yellow-outline-with-hover"
                    onClick={onGetDetails}
                    type={"button"}
                >
                    Get Details
                </button>
            </div>

            {lookupChatDetail &&
                <div className="mt-4">
                    <div className="font-bold text-lg">Chat Details</div>
                    <table className="table-auto border w-full">
                        <tbody>
                            <tr><td className="border text-left p-2 font-bold">ID</td><td className="border text-center">{lookupChatDetail.id}</td></tr>
                            <tr><td className="border text-left p-2 font-bold">Name</td><td className="border text-center">{lookupChatDetail.title ?? lookupChatDetail.username}</td></tr>
                            <tr><td className="border text-left p-2 font-bold">Type</td><td className="border text-center">{lookupChatDetail.type.toUpperCase()}</td></tr>
                        </tbody>
                    </table>

                    <div className="flex gap-4">
                        <button
                            className="mt-4 w-full button-outline-with-hover"
                            onClick={clearAll}
                            type={"button"}
                        >
                            Clear
                        </button>
                        <button
                            className="mt-4 w-full button-yellow-outline-with-hover"
                            onClick={onAddRecipient}
                            type={"button"}
                        >
                            Add as Recipient
                        </button>
                    </div>
                </div>
            }

            {isLoading && <MidasShimmering />}

            {recipientList.length > 0 &&
                <table className="table-auto border w-full mt-4">
                    <thead className="p-2 shadow-sn bg-gray-100 sticky">
                        <tr>
                            <th className="border"></th>
                            <th className="border">ID</th>
                            <th className="border">Name</th>
                            <th className="border">Type</th>
                            <th className="border"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {recipientList.map((item, index) => (
                            <tr key={item.id}>
                                <td className="border text-center p-2">{index + 1}</td>
                                <td className="border text-center p-2">{item.id}</td>
                                <td className="border text-center p-2">{item.name}</td>
                                <td className="border text-center p-2">{item.type.toUpperCase()}</td>
                                <td className="border text-center m-auto p-2">
                                    <FaTrashAlt size={24} color="red" onClick={() => deleteRecipient(index)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div >
    )
}