import { PATH_TELEGRAM_SEND_MESSAGE } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const SendMessageUseCase: (
    recipients: string[],
    isTest: boolean,
    messageBody: string,
) => Promise<string> = async (recipients, isTest, messageBody) => {
    try {
        let formData = new FormData();
        formData.append("recipient", recipients.join(";"));
        formData.append("messageBody", messageBody);
        formData.append("isTest", isTest + "");

        let response = await client.post(PATH_TELEGRAM_SEND_MESSAGE, formData);
        return response.data.message;
    } catch (e) {
        throw new Error(e.response.data.message);
    }
}