import { AccordionComponent } from "@Components/accordion/Accordion.component";
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { ToggleButton } from "@Components/buttons/ToggleButton.component";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { TelegramRecipientModel } from "@Pages/telegram/data/recipient.model";
import { FC, useEffect, useState } from "react"
import { RecipientListProps } from "../TelegramMain.page";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import { TELEGRAM_EXPIRED_MESSAGE, TELEGRAM_PAGE_PUBLISH_MESSAGE } from "@Pages/setting/utils/const";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { LoadSettingUsecase } from "@Pages/setting/domain/LoadSetting.usecase";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";

export const AutoMessageSection: FC<RecipientListProps> = ({ recipientList }) => {
    return (
        <div>
            <div className="mt-4 font-bold text-lg">Auto Message Config</div>

            <ExpiredRoleMessages />
            <PagePublishMessages recipientList={recipientList} />
        </div>
    )
}

const ExpiredRoleMessages: FC = () => {
    const toasterContext = UseToasterContext();

    const [isLoading, setIsLoading] = useState(false);

    const [sevenDaysActive, setSevenDaysActive] = useState(false);
    const [sevenDaysMessage, setSevenDaysMessage] = useState("");

    const [fourteenDaysActive, setFourteenDaysActive] = useState(false);
    const [fourteenDaysMessage, setFourteenDaysMessage] = useState("");

    const [twentyOneDaysActive, setTwentyOneDaysActive] = useState(false);
    const [twentyOneDaysMessage, setTwentyOneDaysMessage] = useState("");

    const [oneMonthActive, setOneMonthActive] = useState(false);
    const [oneMonthMessage, setOneMonthMessage] = useState("");

    useEffect(() => {
        loadCurrentData()
    }, []);

    const loadCurrentData = async () => {
        try {
            let response = await LoadSettingUsecase(TELEGRAM_EXPIRED_MESSAGE);
            if (response[TELEGRAM_EXPIRED_MESSAGE]) {
                let data = JSON.parse(response[TELEGRAM_EXPIRED_MESSAGE]);

                let sevenDaysData = data.sevenDays
                let fourteenDaysData = data.fourteenDays
                let twentyOneDaysData = data.twentyOneDays
                let oneMonthData = data.thirtyDays

                setSevenDaysActive(sevenDaysData.isActive);
                setSevenDaysMessage(sevenDaysData.message);
                setFourteenDaysActive(fourteenDaysData.isActive);
                setFourteenDaysMessage(fourteenDaysData.message);
                setTwentyOneDaysActive(twentyOneDaysData.isActive);
                setTwentyOneDaysMessage(twentyOneDaysData.message);
                setOneMonthActive(oneMonthData.isActive);
                setOneMonthMessage(oneMonthData.message);
            }
        } catch (e) {
            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel(e.message, "", ToasterType.DANGER),
            ]);
        } finally {
            setIsLoading(false);
        }
    }

    const onSaveClicked = async () => {
        if (isLoading) return;

        try {
            setIsLoading(true);

            let newData = {
                "sevenDays": {
                    "isActive": sevenDaysActive,
                    "message": sevenDaysMessage
                },
                "fourteenDays": {
                    "isActive": fourteenDaysActive,
                    "message": fourteenDaysMessage
                },
                "twentyOneDays": {
                    "isActive": twentyOneDaysActive,
                    "message": twentyOneDaysMessage
                },
                "thirtyDays": {
                    "isActive": oneMonthActive,
                    "message": oneMonthMessage
                }
            }
            await SaveSettingsUsecase(true, TELEGRAM_EXPIRED_MESSAGE, JSON.stringify(newData));
        } catch (e) {
            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel("Failed!", e.message, ToasterType.DANGER),
            ]);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="mt-4">
            <AccordionComponent title="Expired Messages" isOpen={false}>
                <div className="p-4 border">
                    {isLoading && <MidasShimmering />}
                    {!isLoading &&
                        <div>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div>
                                    <div className="flex justify-between">
                                        <div className="font-bold w-full my-auto">7 Days Warning</div>
                                        <ToggleButton isActive={sevenDaysActive} onToggle={setSevenDaysActive} />
                                    </div>
                                    {sevenDaysActive && <textarea className="w-full mt-2 border rounded p-2" value={sevenDaysMessage} onChange={e => setSevenDaysMessage(e.target.value)} />}
                                </div>
                                <div>
                                    <div className="flex justify-between">
                                        <div className="font-bold w-full my-auto">14 Days Warning</div>
                                        <ToggleButton isActive={fourteenDaysActive} onToggle={setFourteenDaysActive} />
                                    </div>
                                    {fourteenDaysActive && <textarea className="w-full mt-2 border rounded p-2" value={fourteenDaysMessage} onChange={e => setFourteenDaysMessage(e.target.value)} />}
                                </div>
                                <div>
                                    <div className="flex justify-between">
                                        <div className="font-bold w-full my-auto">21 Days Warning</div>
                                        <ToggleButton isActive={twentyOneDaysActive} onToggle={setTwentyOneDaysActive} />
                                    </div>
                                    {twentyOneDaysActive && <textarea className="w-full mt-2 border rounded p-2" value={twentyOneDaysMessage} onChange={e => setTwentyOneDaysMessage(e.target.value)} />}
                                </div>
                                <div>
                                    <div className="flex justify-between">
                                        <div className="font-bold w-full my-auto">1 Month Warning</div>
                                        <ToggleButton isActive={oneMonthActive} onToggle={setOneMonthActive} />
                                    </div>
                                    {oneMonthActive && <textarea className="w-full mt-2 border rounded p-2" value={oneMonthMessage} onChange={e => setOneMonthMessage(e.target.value)} />}
                                </div>
                            </div>

                            <div className="h-1 border-t mt-4"></div>

                            <blockquote className="mt-4">
                                <div className="text-lg font-bold">Available Format</div>
                                <div>
                                    {`<b>bold</b>, <strong>bold</strong>`} <br />
                                    {`<i>italic</i>, <em>italic</em>`} <br />
                                    {`<u>underline</u>, <ins>underline</ins>`} <br />
                                    {`<s>strikethrough</s>, <strike>strikethrough</strike>, <del>strikethrough</del>`} <br />
                                    {`<span class="tg-spoiler">spoiler</span>, <tg-spoiler>spoiler</tg-spoiler>`} <br />
                                    {`<b>bold <i>italic bold <s>italic bold strikethrough <span class="tg-spoiler">italic bold strikethrough spoiler</span></s> <u>underline italic bold</u></i> bold</b>`} <br />
                                    {`<a href="http://www.example.com/">inline URL</a>`} <br />
                                    {`<a href="tg://user?id=123456789">inline mention of a user</a>`} <br />
                                    {`<tg-emoji emoji-id="5368324170671202286">👍</tg-emoji>`} <br />
                                    {`<code>inline fixed-width code</code>`} <br />
                                    {`<pre>pre-formatted fixed-width code block</pre>`} <br />
                                    {`<pre><code class="language-python">pre-formatted fixed-width code block written in the Python programming language</code></pre>`} <br />
                                    {`<blockquote>Block quotation started\nBlock quotation continued\nThe last line of the block quotation</blockquote>`} <br />
                                    {`<blockquote expandable>Expandable block quotation started\nExpandable block quotation continued\nExpandable block quotation continued\nHidden by default part of the block quotation started\nExpandable block quotation continued\nThe last line of the block quotation</blockquote>`} <br />
                                </div>
                            </blockquote>

                            <blockquote className="mt-4">
                                <div className="text-lg font-bold">Available Data</div>
                                <div>
                                    {`{{name}} => User full name`} <br />
                                    {`{{email}} => User email`} <br />
                                    {`{{role}} => User role that will be expired`} <br />
                                </div>
                            </blockquote>

                            <YellowHoverButton label="Save" customClass="w-full mt-4" onClick={onSaveClicked} />
                        </div>
                    }
                </div>
            </AccordionComponent>
        </div >
    )
}

const PagePublishMessages: FC<RecipientListProps> = ({ recipientList }) => {
    const toasterContext = UseToasterContext();

    const [isLoading, setIsLoading] = useState(false);

    const [analysisActive, setAnalysisActive] = useState(false);
    const [analysisMessage, setAnalysisMessage] = useState("");
    const [analysisRecipients, setAnalysisRecipients] = useState<TelegramRecipientModel[]>([]);

    const [watchlistActive, setWatchlistActive] = useState(false);
    const [watchlistMessage, setWatchlistMessage] = useState("");
    const [watchlistRecipients, setWatchlistRecipients] = useState<TelegramRecipientModel[]>([]);

    const [portfolioActive, setPortfolioActive] = useState(false);
    const [portfolioMessage, setPortfolioMessage] = useState("");
    const [portfolioRecipients, setPortfolioRecipients] = useState<TelegramRecipientModel[]>([]);

    const [weeklyActive, setWeeklyActive] = useState(false);
    const [weeklyMessage, setWeeklyMessage] = useState("");
    const [weeklyRecipients, setWeeklyRecipients] = useState<TelegramRecipientModel[]>([]);

    useEffect(() => {
        loadCurrentData()
    }, []);

    const loadCurrentData = async () => {
        try {
            let response = await LoadSettingUsecase(TELEGRAM_PAGE_PUBLISH_MESSAGE);
            if (response[TELEGRAM_PAGE_PUBLISH_MESSAGE]) {
                let data = JSON.parse(response[TELEGRAM_PAGE_PUBLISH_MESSAGE]);

                let analysisData = data.analysis;
                let watchlistData = data.watchlist;
                let portfolioData = data.portfolio;
                let weeklyMeetingData = data.weeklyMeeting;

                setAnalysisActive(analysisData.isActive);
                setAnalysisMessage(analysisData.message);
                setAnalysisRecipients(analysisData.recipients);

                setWatchlistActive(watchlistData.isActive);
                setWatchlistMessage(watchlistData.message);
                setWatchlistRecipients(watchlistData.recipients);

                setPortfolioActive(portfolioData.isActive);
                setPortfolioMessage(portfolioData.message);
                setPortfolioRecipients(portfolioData.recipients);

                setWeeklyActive(weeklyMeetingData.isActive);
                setWeeklyMessage(weeklyMeetingData.message);
                setWeeklyRecipients(weeklyMeetingData.recipients);
            }
        } catch (e) {
            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel(e.message, "", ToasterType.DANGER),
            ]);
        } finally {
            setIsLoading(false);
        }
    }

    const onSaveClicked = async () => {
        if (isLoading) return;

        try {
            setIsLoading(true);

            let newData = {
                "analysis": {
                    "isActive": analysisActive,
                    "message": analysisMessage,
                    "recipients": analysisRecipients,
                },
                "watchlist": {
                    "isActive": watchlistActive,
                    "message": watchlistMessage,
                    "recipients": watchlistRecipients,
                },
                "portfolio": {
                    "isActive": portfolioActive,
                    "message": portfolioMessage,
                    "recipients": portfolioRecipients,
                },
                "weeklyMeeting": {
                    "isActive": weeklyActive,
                    "message": weeklyMessage,
                    "recipients": weeklyRecipients,
                }
            }
            await SaveSettingsUsecase(true, TELEGRAM_PAGE_PUBLISH_MESSAGE, JSON.stringify(newData));
        } catch (e) {
            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel("Failed!", e.message, ToasterType.DANGER),
            ]);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="mt-4">
            <AccordionComponent title="Page Publish Messages" isOpen={false}>
                <div className="p-4 border">
                    {isLoading && <MidasShimmering />}
                    {!isLoading && <div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                            <div>
                                <div className="flex justify-between">
                                    <div className="font-bold w-full my-auto">New Analysis</div>
                                    <ToggleButton isActive={analysisActive} onToggle={setAnalysisActive} />
                                </div>
                                {analysisActive &&
                                    recipientList.length > 0 && (
                                        <div className="flex gap-4">
                                            {recipientList.map(item => (
                                                <div key={item.id} className="flex gap-2">
                                                    <input type="checkbox" value={item.id} checked={analysisRecipients.some(recipient => recipient.id == item.id)} onChange={e => {
                                                        if (e.target.checked) {
                                                            if (analysisRecipients.some(recipient => recipient.id == item.id) == false) {
                                                                setAnalysisRecipients([...analysisRecipients, item]);
                                                            }
                                                        } else {
                                                            setAnalysisRecipients(analysisRecipients.filter(recipient => recipient.id != item.id));
                                                        }
                                                    }} />
                                                    <div>{item.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }
                                {analysisActive && <textarea className="w-full mt-2 border rounded p-2" value={analysisMessage} onChange={e => setAnalysisMessage(e.target.value)} />}
                            </div>
                            <div>
                                <div className="flex justify-between">
                                    <div className="font-bold w-full my-auto">New Watchlist</div>
                                    <ToggleButton isActive={watchlistActive} onToggle={setWatchlistActive} />
                                </div>
                                {watchlistActive &&
                                    recipientList.length > 0 && (
                                        <div className="flex gap-4">
                                            {recipientList.map(item => (
                                                <div key={item.id} className="flex gap-2">
                                                    <input type="checkbox" value={item.id} checked={watchlistRecipients.some(recipient => recipient.id == item.id)} onChange={e => {
                                                        if (e.target.checked) {
                                                            if (watchlistRecipients.some(recipient => recipient.id == item.id) == false) {
                                                                setWatchlistRecipients([...watchlistRecipients, item]);
                                                            }
                                                        } else {
                                                            setWatchlistRecipients(watchlistRecipients.filter(recipient => recipient.id != item.id));
                                                        }
                                                    }} />
                                                    <div>{item.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }
                                {watchlistActive && <textarea className="w-full mt-2 border rounded p-2" value={watchlistMessage} onChange={e => setWatchlistMessage(e.target.value)} />}
                            </div>
                            <div>
                                <div className="flex justify-between">
                                    <div className="font-bold w-full my-auto">New Portfolio</div>
                                    <ToggleButton isActive={portfolioActive} onToggle={setPortfolioActive} />
                                </div>
                                {portfolioActive &&
                                    recipientList.length > 0 && (
                                        <div className="flex gap-4">
                                            {recipientList.map(item => (
                                                <div key={item.id} className="flex gap-2">
                                                    <input type="checkbox" value={item.id} checked={portfolioRecipients.some(recipient => recipient.id == item.id)} onChange={e => {
                                                        if (e.target.checked) {
                                                            if (portfolioRecipients.some(recipient => recipient.id == item.id) == false) {
                                                                setPortfolioRecipients([...portfolioRecipients, item]);
                                                            }
                                                        } else {
                                                            setPortfolioRecipients(portfolioRecipients.filter(recipient => recipient.id != item.id));
                                                        }
                                                    }} />
                                                    <div>{item.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }
                                {portfolioActive && <textarea className="w-full mt-2 border rounded p-2" value={portfolioMessage} onChange={e => setPortfolioMessage(e.target.value)} />}
                            </div>
                            <div>
                                <div className="flex justify-between">
                                    <div className="font-bold w-full my-auto">New Weekly Meeting</div>
                                    <ToggleButton isActive={weeklyActive} onToggle={setWeeklyActive} />
                                </div>
                                {weeklyActive &&
                                    recipientList.length > 0 && (
                                        <div className="flex gap-4">
                                            {recipientList.map(item => (
                                                <div key={item.id} className="flex gap-2">
                                                    <input type="checkbox" value={item.id} checked={weeklyRecipients.some(recipient => recipient.id == item.id)} onChange={e => {
                                                        console.log(e.target.checked);
                                                        console.log(weeklyRecipients);
                                                        if (e.target.checked) {
                                                            if (weeklyRecipients.some(recipient => recipient.id == item.id) == false) {
                                                                setWeeklyRecipients([...weeklyRecipients, item]);
                                                            }
                                                        } else {
                                                            setWeeklyRecipients(weeklyRecipients.filter(recipient => recipient.id != item.id));
                                                        }
                                                    }} />
                                                    <div>{item.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }
                                {weeklyActive && <textarea className="w-full mt-2 border rounded p-2" value={weeklyMessage} onChange={e => setWeeklyMessage(e.target.value)} />}
                            </div>
                        </div>

                        <div className="h-1 border-t mt-4"></div>

                        <blockquote className="mt-4">
                            <div className="text-lg font-bold">Available Format</div>
                            <div>
                                {`<b>bold</b>, <strong>bold</strong>`} <br />
                                {`<i>italic</i>, <em>italic</em>`} <br />
                                {`<u>underline</u>, <ins>underline</ins>`} <br />
                                {`<s>strikethrough</s>, <strike>strikethrough</strike>, <del>strikethrough</del>`} <br />
                                {`<span class="tg-spoiler">spoiler</span>, <tg-spoiler>spoiler</tg-spoiler>`} <br />
                                {`<b>bold <i>italic bold <s>italic bold strikethrough <span class="tg-spoiler">italic bold strikethrough spoiler</span></s> <u>underline italic bold</u></i> bold</b>`} <br />
                                {`<a href="http://www.example.com/">inline URL</a>`} <br />
                                {`<a href="tg://user?id=123456789">inline mention of a user</a>`} <br />
                                {`<tg-emoji emoji-id="5368324170671202286">👍</tg-emoji>`} <br />
                                {`<code>inline fixed-width code</code>`} <br />
                                {`<pre>pre-formatted fixed-width code block</pre>`} <br />
                                {`<pre><code class="language-python">pre-formatted fixed-width code block written in the Python programming language</code></pre>`} <br />
                                {`<blockquote>Block quotation started\nBlock quotation continued\nThe last line of the block quotation</blockquote>`} <br />
                                {`<blockquote expandable>Expandable block quotation started\nExpandable block quotation continued\nExpandable block quotation continued\nHidden by default part of the block quotation started\nExpandable block quotation continued\nThe last line of the block quotation</blockquote>`} <br />
                            </div>
                        </blockquote>

                        <blockquote className="mt-4">
                            <div className="text-lg font-bold">Available Data</div>
                            <div>
                                {`{{title}} => Page Title (Not available for Weekly Meeting)`} <br />
                                {`{{url}} => Page URL`} <br />
                            </div>
                        </blockquote>

                        <YellowHoverButton label="Save" customClass="w-full mt-4" onClick={onSaveClicked} />
                    </div>}
                </div>
            </AccordionComponent>
        </div >
    )
}