import { FC } from "react";

export type ToggleButtonProps = {
    isActive: boolean;
    onToggle: (newValue: boolean) => void;
};

export const ToggleButton: FC<ToggleButtonProps> = ({ isActive, onToggle }) => {
    return (<button className={`toggle-btn ${isActive ? "toggled" : ""}`} onClick={() => onToggle(!isActive)}>
        <div className="thumb"></div>
    </button>)
}