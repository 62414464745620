import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { SendMessageUseCase } from "@Pages/telegram/domain/SendMessage.usecase";
import { FC } from "react"
import { useForm } from "react-hook-form";
import { RecipientListProps } from "../TelegramMain.page";

export type SendMessageFormInput = {
    recipients: string[];
    messageBody: string;
}

export const SendMessageSection: FC<RecipientListProps> = ({ recipientList }) => {
    const toasterContext = UseToasterContext();

    const { register, watch } = useForm<SendMessageFormInput>();

    const onSendMessage = async (isTesting: boolean) => {
        try {
            let recipients = watch("recipients");
            let messageBody = watch("messageBody");

            let result = await SendMessageUseCase(recipients, isTesting, messageBody);

            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel(result, "", ToasterType.SUCCESS),
            ]);
        } catch (e) {
            toasterContext.setToastList([
                ...toasterContext.toastList,
                new ToasterModel(e.message, "", ToasterType.DANGER),
            ]);
        }
    }

    return (
        <div>
            <div className="mt-4 font-bold text-lg">Recipient(s)</div>

            <div className="mt-2">
                {recipientList.length == 0 && <MidasShimmering />}
                {recipientList.length > 0 && (
                    <div className="flex gap-4">
                        {recipientList.map(item => (
                            <div key={item.id} className="flex gap-2">
                                <input type="checkbox" value={item.id} {...register("recipients")} />
                                <div>{item.name}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="mt-4 font-bold text-lg">Message Body</div>
            <textarea
                className="w-full mt-2 border rounded p-2"
                {...register("messageBody", {
                    required: "Message is required",
                })} />

            <div className="flex justify-between">
                <div className="my-auto">Check supported HTML tag <a className="font-bold" target="_blank" href="https://core.telegram.org/bots/api#html-style">here</a></div>
                <div className="flex justify-end gap-4">
                    <button
                        className="mt-4 button-outline-with-hover"
                        onClick={() => onSendMessage(true)}
                        type={"button"}
                    >
                        Send Test
                    </button>

                    <button
                        className="mt-4 button-yellow-outline-with-hover"
                        onClick={() => onSendMessage(false)}
                        type={"button"}
                    >
                        Send Message
                    </button>
                </div>
            </div>

            <blockquote className="mt-4">
                <div className="text-lg font-bold">Available Format</div>
                <div>
                    {`<b>bold</b>, <strong>bold</strong>`} <br />
                    {`<i>italic</i>, <em>italic</em>`} <br />
                    {`<u>underline</u>, <ins>underline</ins>`} <br />
                    {`<s>strikethrough</s>, <strike>strikethrough</strike>, <del>strikethrough</del>`} <br />
                    {`<span class="tg-spoiler">spoiler</span>, <tg-spoiler>spoiler</tg-spoiler>`} <br />
                    {`<b>bold <i>italic bold <s>italic bold strikethrough <span class="tg-spoiler">italic bold strikethrough spoiler</span></s> <u>underline italic bold</u></i> bold</b>`} <br />
                    {`<a href="http://www.example.com/">inline URL</a>`} <br />
                    {`<a href="tg://user?id=123456789">inline mention of a user</a>`} <br />
                    {`<tg-emoji emoji-id="5368324170671202286">👍</tg-emoji>`} <br />
                    {`<code>inline fixed-width code</code>`} <br />
                    {`<pre>pre-formatted fixed-width code block</pre>`} <br />
                    {`<pre><code class="language-python">pre-formatted fixed-width code block written in the Python programming language</code></pre>`} <br />
                    {`<blockquote>Block quotation started\nBlock quotation continued\nThe last line of the block quotation</blockquote>`} <br />
                    {`<blockquote expandable>Expandable block quotation started\nExpandable block quotation continued\nExpandable block quotation continued\nHidden by default part of the block quotation started\nExpandable block quotation continued\nThe last line of the block quotation</blockquote>`} <br />
                </div>
            </blockquote>
        </div >
    )
}