export const GENERAL_SELECTED_PRODUCTS = "GENERAL_SELECTED_PRODUCTS";
export const GENERAL_TESTIMONI = "GENERAL_TESTIMONI";
export const HOMEPAGE_PORTFOLIO = "HOMEPAGE_PORTFOLIO";
export const HOMEPAGE_SHOWCASE = "HOMEPAGE_SHOWCASE";
export const MEMBERSHIP_HEADER_VIDEO = "MEMBERSHIP_HEADER_VIDEO";
export const MEMBERSHIP_INTRODUCTION_VIDEO = "MEMBERSHIP_INTRODUCTION_VIDEO";
export const MEMBERSHIP_SHOWCASE = "MEMBERSHIP_SHOWCASE";
export const MEMBERSHIP_CAROUSEL = "MEMBERSHIP_CAROUSEL";
export const MEMBERSHIP_FINDING_STOCKS = "MEMBERSHIP_FINDING_STOCKS";
export const MEMBERSHIP_FAQ = "MEMBERSHIP_FAQ";
export const MEMBERSHIP_FAQ_EMAIL = "MEMBERSHIP_FAQ_EMAIL";
export const STOCK_KEY_TEASER = "STOCK_KEY_TEASER";
export const PHILOSOPHY_TOP_HEADER = "PHILOSOPHY_TOP_HEADER";
export const PHILOSOPHY_CRITERIA = "PHILOSOPHY_CRITERIA";

export const CHECKOUT_ADDITIONAL_INFO = "CHECKOUT_ADDITIONAL_INFO";
export const CHECKOUT_AVAILABLE_PAYMENTS = "CHECKOUT_AVAILABLE_PAYMENTS";
export const RESOURCE_SEPARATOR = "RESOURCE_SEPARATOR";
export const MIDAS_API_BASE_URL = "MIDAS_API_BASE_URL";
export const MIDAS_ADMIN_BASE_URL = "MIDAS_ADMIN_BASE_URL";
export const MIDAS_USER_BASE_URL = "MIDAS_USER_BASE_URL";
export const XENDIT_SECRET_KEY = "XENDIT_SECRET_KEY";
export const GET_RESPONSE_API_KEY = "GET_RESPONSE_API_KEY";
export const XENDIT_CALLBACK_TOKEN = "XENDIT_CALLBACK_TOKEN";
export const AVAILABLE_PAYMENT_METHODS = "AVAILABLE_PAYMENT_METHODS";

export const AFFILIATE_MINIMUM_WITHDRAW_AMOUNT =
  "AFFILIATE_MINIMUM_WITHDRAW_AMOUNT";
export const AFFILIATE_DISCOUNT_AMOUNT = "AFFILIATE_DISCOUNT_AMOUNT";
export const AFFILIATE_TUTORIAL_VIDEO_LINK = "AFFILIATE_TUTORIAL_VIDEO_LINK";
export const AFFILIATE_TELEGRAM_GROUP = "AFFILIATE_TELEGRAM_GROUP";

export const MEMBER_OFFER_MEMBER_ONLY = "MEMBER_OFFER_MEMBER_ONLY";
export const MEMBER_OFFER_PORTOFOLIO_ONLY = "MEMBER_OFFER_PORTOFOLIO_ONLY";
export const MEMBER_OFFER_MEMBER_AND_PORTOFOLIO =
  "MEMBER_OFFER_MEMBER_AND_PORTOFOLIO";

export const MEMBER_UPGRADE_MEMBER = "MEMBER_UPGRADE_MEMBER";
export const MEMBER_UPGRADE_PORTOFOLIO = "MEMBER_UPGRADE_PORTOFOLIO";
export const USER_UPGRADE_SERVICES = "USER_UPGRADE_SERVICES";

export const MEMBER_SELECTED_STOCKS = "MEMBER_SELECTED_STOCKS";
export const MEMBER_PORTFOLIO_STOCKS = "MEMBER_PORTFOLIO_STOCKS";
export const MEMBER_WATCHLIST_STOCKS = "MEMBER_WATCHLIST_STOCKS";
export const FREE_TRIALS_SELECTED_STOCK = "FREE_TRIALS_SELECTED_STOCK";

export const DISCUSSION_GROUP = "DISCUSSION_GROUP";
export const STRATEGY_ROOM = "STRATEGY_ROOM";
export const PORTOFOLIO_GROUP = "PORTOFOLIO_GROUP";

export const STOCK_TREASURE_MAPS_TOOLS = "STOCK_TREASURE_MAPS_TOOLS";
export const STOCK_PORTOFOLIO_TOOLS = "STOCK_PORTOFOLIO_TOOLS";

export const LIST_EMITEN_BY_SECTORS = "LIST_EMITEN_BY_SECTORS";

export const EBOOK_URL = "EBOOK_URL";
export const LINK_ZOOM_WM = "LINK_ZOOM_WM";

export const TELEGRAM_RECIPIENT_LIST = "TELEGRAM_RECIPIENT_LIST";
export const TELEGRAM_EXPIRED_MESSAGE = "TELEGRAM_EXPIRED_MESSAGE";
export const TELEGRAM_PAGE_PUBLISH_MESSAGE = "TELEGRAM_PAGE_PUBLISH_MESSAGE";

export const SETTING_SITE_KEYS = [
  CHECKOUT_ADDITIONAL_INFO,
  CHECKOUT_AVAILABLE_PAYMENTS,
  RESOURCE_SEPARATOR,
  MIDAS_API_BASE_URL,
  MIDAS_ADMIN_BASE_URL,
  MIDAS_USER_BASE_URL,
  XENDIT_SECRET_KEY,
  XENDIT_CALLBACK_TOKEN,
  GET_RESPONSE_API_KEY,
  AVAILABLE_PAYMENT_METHODS,
  DISCUSSION_GROUP,
  STRATEGY_ROOM,
  PORTOFOLIO_GROUP,
  STOCK_TREASURE_MAPS_TOOLS,
  STOCK_PORTOFOLIO_TOOLS,
  EBOOK_URL,
  LINK_ZOOM_WM,
];

export const SETTING_STATIC_PAGE_KEYS = [
  GENERAL_SELECTED_PRODUCTS,
  GENERAL_TESTIMONI,
  HOMEPAGE_PORTFOLIO,
  HOMEPAGE_SHOWCASE,
  MEMBERSHIP_HEADER_VIDEO,
  MEMBERSHIP_INTRODUCTION_VIDEO,
  MEMBERSHIP_FINDING_STOCKS,
  MEMBERSHIP_SHOWCASE,
  MEMBERSHIP_CAROUSEL,
  MEMBERSHIP_FAQ,
  MEMBERSHIP_FAQ_EMAIL,
  STOCK_KEY_TEASER,
  PHILOSOPHY_TOP_HEADER,
  PHILOSOPHY_CRITERIA,
  MEMBER_OFFER_MEMBER_ONLY,
  MEMBER_OFFER_PORTOFOLIO_ONLY,
  MEMBER_OFFER_MEMBER_AND_PORTOFOLIO,
  MEMBER_UPGRADE_MEMBER,
  MEMBER_UPGRADE_PORTOFOLIO,
  USER_UPGRADE_SERVICES,
  MEMBER_SELECTED_STOCKS,
  MEMBER_PORTFOLIO_STOCKS,
  MEMBER_WATCHLIST_STOCKS,
  FREE_TRIALS_SELECTED_STOCK,
];

export const SETTING_AFFILIATE_KEYS = [
  AFFILIATE_MINIMUM_WITHDRAW_AMOUNT,
  AFFILIATE_DISCOUNT_AMOUNT,
  AFFILIATE_TUTORIAL_VIDEO_LINK,
  AFFILIATE_TELEGRAM_GROUP,
];
