export const API_BASE_URL = "https://api.midascuan.id/";
export const API_PATH = "";

export const API_PATH_AUTH = "auth/";
export const API_PATH_LOGIN = API_PATH_AUTH + "login";
export const API_PATH_REGISTER = API_PATH_AUTH + "register";

export const PATH_AFFILIATES = "affiliates";
export const PATH_ORDERS = "orders";
export const PATH_PAGES = "pages";
export const PATH_ANALYSIS = "analysis";
export const PATH_PRODUCTS = "products";
export const PATH_PROFILE = "profile";
export const PATH_RECORDS = "records";
export const PATH_RESOURCES = "resources";
export const PATH_ROLES = "roles";
export const PATH_LOADS_ROLES = "roles/loads";
export const PATH_SETTINGS = "site-options";
export const PATH_LOAD_BATCH_SETTINGS = `${PATH_SETTINGS}/batch-load`;
export const PATH_JARVIS = "jarvis";
export const PATH_USERS = "users";
export const PATH_USERS_EXPIRED = "expired-users";
export const PATH_VOUCHERS = "vouchers";
export const PATH_COURSES = "courses";
export const PATH_COURSES_SECTION = "section";
export const PATH_COURSES_ITEM = "item";

export const PATH_GET_RESPONSE = "getResponse";

export const PATH_GR_ACCOUNT = PATH_GET_RESPONSE + "/account";

export const PATH_GR_AUTO_RESPONDER = PATH_GET_RESPONSE + "/autoresponder";

export const PATH_GR_CAMPAIGNS = PATH_GET_RESPONSE + "/campaigns";
export const PATH_GR_CAMPAIGNS_CONTACTS = PATH_GR_CAMPAIGNS + "/{id}/contacts";

export const PATH_GR_CONTACTS = PATH_GET_RESPONSE + "/contacts";
export const PATH_GR_CONTACT_ACTIVITIES = PATH_GR_CONTACTS + "/{id}/activities";

export const PATH_GR_SHOPS = PATH_GET_RESPONSE + "/shops";
export const PATH_GR_SHOP_DETAIL = PATH_GR_SHOPS + "/{id}";

export const PATH_GR_LANDING_PAGES = PATH_GET_RESPONSE + "/landing-pages";
export const PATH_GR_LANDING_PAGES_DETAIL = PATH_GR_LANDING_PAGES + "/{id}";

export const PATH_GR_PRODUCTS = PATH_GET_RESPONSE + "/products";
export const PATH_GR_PRODUCTS_DETAIL = PATH_GR_PRODUCTS + "/{id}";

export const PATH_TELEGRAM = "telegram";
export const PATH_TELEGRAM_SEND_MESSAGE = PATH_TELEGRAM + "/sendMessage";
export const PATH_TELEGRAM_GET_CHAT_DETAILS = PATH_TELEGRAM + "/getChatDetail";